import React from 'react'

import projeto1 from '../../../assets/images/projeto-1.png'
import projeto2 from '../../../assets/images/projeto-2.png'
import projeto3 from '../../../assets/images/projeto-3.1.png'
import projeto4 from '../../../assets/images/projeto-4.png'


const Main = () => {
  return (
    <>
        <br />
        <br />
        <section className="row_am how_it_works" id="how_it_work">
            <div className="container">
                <div className={`how_it_inner`}>
                    <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                        <h2><span>Nossos projetos</span> - concluídos</h2>
                        <br />
                        <p>
                            A Logma  realiza a manutenção, reforma e modernização de unidades fabris. 
                            Nossos projetos são acompanhados <br /> 
                            e executados por profissionais experientes
                            que se revezam durante os turnos em regime de 24 horas trabalhadas diariamente <br />
                            para garantir a perfeita execução dos serviços. Com empenho e dedicação,  
                            conseguimos solucionar problemas anteriormente existentes <br />
                            e otimizar a produção 
                            das fábricas cumprindo os prazos exigidos com qualidade e garantindo a segurança 
                            total de todos os envolvidos.
                        </p>
                    </div>
                    <br />
                    <br />
                    <div className="step_block">
                        <ul>
                            <li>
                                <div className="step_text" data-aos="fade-right" data-aos-duration="1500">
                                <h4>Ubyfol Fertilizantes</h4>
                                <span>Uberaba/MG</span>
                                <p>Montagem da Nova Linha – Carregamento Bay na Planta de Sais (Jateamento e Pintura)</p>
                                </div>
                                <div className="step_number">
                                <h3>01</h3>
                                </div>
                                <div className="step_img" data-aos="fade-left" data-aos-duration="1500">
                                <img src={projeto1} alt="step" />
                                </div>
                            </li>
                            <li>
                                <div className="step_text" data-aos="fade-left" data-aos-duration="1500">
                                <h4>Mineração Morro Verde</h4>
                                <span>Pratápolis/MG</span>
                                <p>Montagem EletroMecânica da Planta de Calcário Agricola</p>
                                </div>
                                <div className="step_number"> 
                                <h3>02</h3>
                                </div>
                                <div className="step_img" data-aos="fade-right" data-aos-duration="1500">
                                <img src={projeto2} alt="step" />
                                </div>
                            </li>
                            <li>
                                <div className="step_text" data-aos="fade-right" data-aos-duration="1500">
                                <h4>PA GOLD MINERAÇÃO</h4>
                                <span>Peixoto Azevedo/MT</span>
                                <p>Fornecimento de Homem x Hora por Administração para Ativação do Projeto da Minas de Ouro</p>
                                </div>
                                <div className="step_number">
                                <h3>03</h3>
                                </div>
                                <div className="step_img" data-aos="fade-left" data-aos-duration="1500">
                                <img src={projeto3} alt="step" />
                                </div>
                            </li>
                            <li>
                                <div className="step_text" data-aos="fade-right" data-aos-duration="1500">
                                <h4>Itafos Arraias Mineração e Fertilizantes S.A.</h4>
                                <span>Arraias/TO</span>
                                <p>Manutenção da parada bianual (Elétrica, Instrumentação, Mecânica, Caldeiraria e Inspeção)</p>
                                </div>
                                <div className="step_number"> 
                                <h3>04</h3>
                                </div>
                                <div className="step_img" data-aos="fade-right" data-aos-duration="1500">
                                <img src={projeto4} alt="step" />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Main