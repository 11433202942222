import React , {useEffect } from 'react'
import AOS from "aos";
import OwlCarousel from 'react-owl-carousel'
import Customers from '../HomeMain/Customers/Main'
import AboutOurBusiness from '../HomeMain/AboutOurBusiness/Main'
import AboutUs from '../HomeMain/AboutUs/Main'
import AboutSecure from '../HomeMain/AboutSecure/Main'
import Citizenship from '../HomeMain/Citizenship/Main'
import Expectations from '../HomeMain/Expectations/Main'
import Work from '../HomeMain/Work/Main'

import anim from '../../assets/images/anim_line.png'
import screen1 from '../../assets/images/2.jpg'
import screen2 from '../../assets/images/carrossel-1.png'
import screen3 from '../../assets/images/carrossel-2.png'



const Main = ({setfooter , setnavbar ,setbrdcum}) => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    
    useEffect(() => {
        setfooter({f1 : true})
        setnavbar({n2 : true})
        setbrdcum({b1 : true})
      localStorage.setItem("navbar","home")

    }, [setbrdcum, setfooter, setnavbar])

    const frmae_slider = {
        loop:true,
        margin:10,
        nav:false,
        autoplay: true,
        smartSpeed: 1500,
        dots: true, 
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            1000:{
                items:1
            }
        }
    }

  return (
    <>
        <section className="banner_section home-banner">
            <div className="container">
                <div className="anim_line">
                    <span><img src={anim} alt="anim_line"/></span>
                    <span><img src={anim} alt="anim_line"/></span>
                    <span><img src={anim} alt="anim_line"/></span>
                    <span><img src={anim} alt="anim_line"/></span>
                    <span><img src={anim} alt="anim_line"/></span>
                    <span><img src={anim} alt="anim_line"/></span>
                    <span><img src={anim} alt="anim_line"/></span>
                    <span><img src={anim} alt="anim_line"/></span>
                    <span><img src={anim} alt="anim_line"/></span>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-12"  data-aos="fade-right" data-aos-duration="1500">
                        <div className="banner_text">
                            <h1>Missão dada é <span> MISSÃO</span> cumprida</h1>
                            <br />
                            <p>
                            A empresa tem que servir bem. E servir
                            bem é tratar a obra do cliente como se
                            fosse nossa. O diferencial da Logma está em
                            entender a necessidade de nossos parceiros
                            e em trabalhar junto com eles a fim de
                            superarmos as dificuldades que o projeto
                            possa encontrar, para que as metas sejam
                            alcançadas.
                            </p>
                            <br />
                            <h6><strong>Diretoria</strong></h6>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12"  data-aos="fade-in" data-aos-duration="1500">
                        <OwlCarousel id="frmae_slider" className="owl-carousel owl-theme owl-loaded owl-drag" {...frmae_slider}>
                            <div className="item">
                                <div className="slider_img">
                                    <img src={screen1} alt="slide" />
                                </div>
                            </div>

                            <div className="item">
                                <div className="slider_img">
                                    <img src={screen2} alt="slide" />
                                </div>
                            </div>
                            <div className="item">
                                <div className="slider_img">
                                    <img src={screen3} alt="slide" />
                                </div>
                            </div>
                        </OwlCarousel> 
                    </div>
                </div>
            </div>
        </section>
        <AboutOurBusiness />
        <Customers />
        <AboutUs />
        <Expectations />
        <AboutSecure />
        <Citizenship />
        <Work />
    </>
  )
}

export default Main