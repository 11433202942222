import React from 'react'

import img1 from '../../../assets/images/secure_data.png'
import img2 from '../../../assets/images/Missao.png'
import img3 from '../../../assets/images/visao.png'
import img4 from '../../../assets/images/valores.png'
import img7 from '../../../assets/images/bussines-2.png'

const Main = () => {
  return (
    <>
        <br />
        <br />
        <section className="row_am features_section" id="features">
            <div className="container">
                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                    <br />
                    <h2><span>Nosso negócio</span></h2>
                    <br />
                    <p>
                        Soluções de engenharia e consultoria em montagem eletromecânica, <br />
                        fabricação, manutenção, modernização industrial, construções em aço
                        e locação de equipamentos.
                    </p>
                    <br />
                    <br />
                </div>
                <div className="feature_detail">
                <div className="left_data feature_box">
                    <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
                    <div className="icon">
                        <img src={img1} alt="secure" />
                    </div>
                    <div className="text">
                        <h4>Segurança</h4>
                        <p>
                            Proporcionar um ambiente seguro para os colaboradores com a meta de ZERO ACIDENTES.
                        </p>
                    </div>
                    </div>
                    <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
                    <div className="icon">
                        <img src={img2} alt="mission" />
                    </div>
                    <div className="text">
                        <h4>Missão</h4>
                        <p>
                            Prover soluções de engenharia atendendo as expectativas de nossos
                            clientes.
                        </p>
                    </div>
                    </div>
                </div>
                <div className="right_data feature_box">
                    <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
                    <div className="icon">
                        <img src={img3} alt="vision"/>
                    </div>
                    <div className="text">
                        <h4>Visão</h4>
                        <p>
                            Ser reconhecida nacionalmente entre as melhores empresas nas áreas
                            de atuação e se posicionar em novos mercados.
                        </p>
                    </div>
                    </div>
                    <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
                    <div className="icon">
                        <img src={img4} alt="values" />
                    </div>
                    <div className="text">
                        <h4>Valores</h4>
                        <p>
                            Trabalhamos com honestidade e transparência. sempre comprometida com 
                            as metas e objetivos estabelecidos.
                        </p>
                    </div>
                    </div>
                </div>
                <div className="feature_img" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                    <img src={img7} alt="features" />
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Main