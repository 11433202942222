import React , { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link';

import logo1 from "../../assets/images/logo-logma.png";

const Main = ({navbar}) => {
   
    const location = useLocation()
    const path = location.pathname

    const [mobile, setmobile] = useState()

    useEffect(() => {
        window.scroll(0, 0)
    }, [path]);



  return (
    <>
        {path === "/" ?
        <>
        {navbar.n2 && 
        <header>
            <div className="container">
                <nav className="navbar navbar-expand-lg">
                    <Link to="/" className="navbar-brand">
                        <img src={logo1} alt="logo" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <div className={`toggle-wrap ${mobile && "active"}`}  onClick={() => setmobile(mobile === true ? false : true)}>
                            <span className="toggle-bar"></span>
                        </div>
                    </button>
                    <div className="collapse navbar-collapse" >
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item has_dropdown" >
                                <Link to="/" className="nav-link" >Inicio</Link>
                            </li>
                            <li className="nav-item">
                                <Link smooth to="#features" className="nav-link">Sobre</Link>
                            </li>
                            <li className="nav-item">
                                <Link smooth to="#how_it_work" className="nav-link">trabalhos</Link>
                            </li>
{/*                             <li className="nav-item">
                                <Link to="/contact" className="nav-link" >Contato</Link>
                            </li> */}
                        </ul>
                    </div>
                    <div className={`collapse navbar-collapse mobile-screen ${mobile && "show"}`}  >
                        <ul className="navbar-nav ml-auto">
                            
                            <li className="nav-item">
                                <Link smooth to="#" className="nav-link">Inicio</Link>
                            </li>
                            <li className="nav-item">
                                <Link smooth to="#features" className="nav-link">Sobre</Link>
                            </li>
                            <li className="nav-item">
                                <Link smooth to="#how_it_work" className="nav-link">trabalhos</Link>
                            </li>
{/*                             <li className="nav-item">
                                <Link smooth to="/contact" className="nav-link">Contato</Link>
                            </li> */}
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
        }
        </>
        :
        <>
        {navbar.n2 && 
                <header>
                <div className="container">
                    <nav className="navbar navbar-expand-lg">
                        <Link to="/" className="navbar-brand">
                            <img src={logo1} alt="logo" />
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <div className={`toggle-wrap ${mobile && "active"}`}  onClick={() => setmobile(mobile === true ? false : true)}>
                                <span className="toggle-bar"></span>
                            </div>
                        </button>
                        <div className="collapse navbar-collapse" >
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item has_dropdown" >
                                    <Link to="#" className="nav-link" >Inicio</Link>
                                </li>
                                <li className="nav-item">
                                    <Link smooth to="#features" className="nav-link">Sobre</Link>
                                </li>
                                <li className="nav-item">
                                    <Link smooth to="#how_it_work" className="nav-link">trabalhos</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/contact" className="nav-link" >Contato</Link>
                                </li>
                            </ul>
                        </div>
                        <div className={`collapse navbar-collapse mobile-screen ${mobile && "show"}`}  >
                            <ul className="navbar-nav ml-auto">
                                
                                <li className="nav-item">
                                    <Link smooth to="#" className="nav-link">Inicio</Link>
                                </li>
                                <li className="nav-item">
                                    <Link smooth to="#features" className="nav-link">Sobre</Link>
                                </li>
                                <li className="nav-item">
                                    <Link smooth to="#how_it_work" className="nav-link">trabalhos</Link>
                                </li>
                                <li className="nav-item">
                                    <Link smooth to="/contact" className="nav-link">Contato</Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>}
        </>
    }
    </>
  )
}

export default Main