import React from 'react'

import img1 from '../../../assets/images/download-one.png'
import img2 from '../../../assets/images/download-one-folder.png'

import solda from '../../../assets/images/solda.png'
import aparelho from '../../../assets/images/aparelho.png'

const Main = ({video , dark}) => {
  return (
    <>
        <br />
        <br />
        <section className="row_am about_app_section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="about_img" data-aos="fade-in" data-aos-duration="1500">
                        <div className="frame_img">
                            <img className="moving_position_animatin" src={aparelho} alt="frame"/>
                        </div>
                        <div className="screen_img">
                            <img className="moving_animation" src={solda} alt="screen" />
                        </div>
                        </div>
                    </div>                   
                    <div className="col-lg-6">
                        <div className="about_text">
                        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                            <h2><span>Quem somos</span></h2>
                            <br />
                            <p>
                                A Logma é impulsionada pelo desafio constante de satisfazer os nossos
                                clientes. Fundada em 2004 possue equipes de profissionais
                                altamente qualificados prontos para desenvolver e implantar as
                                melhores soluções em montagem eletromecânica, manutenção
                                mecânica para a indústria e caldeiraria.
                            </p>
                            <p>
                                Com eficácia e dinamismo, consolidou-se como uma empresa de
                                referência no mercado de montagem industrial.
                            </p>
                            <p>
                                Investimos e valorizamos o capital humano, oferecendo aprimoramento
                                técnico por meio de treinamentos específicos que reforçam a
                                preocupação com a segurança e a saúde de nossos colaboradores.
                            </p>
                            <p>
                                As nossas obras são concluídas alcançando META ZERO ACIDENTES
                                COM AFASTAMENTO, o que demonstra o compromisso do nosso grupo
                                com a segurança de todos os colaboradores e pessoas envolvidas em
                                nossos projetos
                            </p>
                            <p>
                                Com excelente estrutura organizacional e logística eficiente,
                                conseguimos atender com agilidade as obras localizadas em todo o
                                País, destacando-se pela organização dos canteiros e pelo bom
                                relacionamento com os clientes e com a comunidade na qual estamos
                                inseridos.
                            </p>
                            <p>
                                Somos compromissados e capaz de atender as mais diferentes
                                necessidades e superar os mais difíceis obstáculos. É assim que
                                construímos a nossa história. É assim que concretizamos parcerias de
                                sucesso
                            </p>
                        </div>
                            <ul className="app_statstic" id="counter" data-aos="fade-in" data-aos-duration="1500">
                                <li>
                                <div className="icon">
                                {video ? <img src="assets/images/download-one.png" alt="download" /> : <img src={img1} alt="download" />} 
                                </div>
                                <div className="text">
                                    <p><span className="counter-value" data-count="100">0</span><span>+</span></p>
                                    <p>Clientes</p>
                                </div>
                                </li>
                                <li>
                                <div className="icon">
                                {video ? <img src="assets/images/followers-one.png" alt="flowers" /> : <img src={img2} alt="Fallowers" /> }
                                </div>
                                <div className="text">
                                    <p><span className="counter-value" data-count="200">0 </span><span>+</span></p>
                                    <p>Projetos Finalizados</p>
                                </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
    </>
  )
}

export default Main