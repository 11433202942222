import React , { useEffect, useState } from 'react'
import { Route, Routes , useLocation} from 'react-router-dom'
import Navbar from '../component/Navbar/Main'
import Footer from '../component/Footer/Main'
import Home from '../component/Home/Main'
import Contact from '../component/Contact/Main'



const Routing = () => {

    const [homepage, sethomepage] = useState(false)
    const location = useLocation();

    useEffect(() => {
      if (location.pathname === "/sign-in" || location.pathname === "/sign-up"  ){
        sethomepage(false)
      }else{
        sethomepage(true)
      }
    }, [location])

    const [footerpage, setfooterpage] = useState(false)
    useEffect(() => {
      if (location.pathname === "/sign-in" || location.pathname === "/sign-up" ){
        setfooterpage(false)
      }else{
        setfooterpage(true)
      }
    }, [location])

    useEffect(() => {
      
      if (localStorage.getItem("navbar") === "darkhome") {
        setfooter({f2 : true})
        setnavbar({n3 : true})
        setbrdcum({b2 : true})
      }
      else if (localStorage.getItem("navbar") === "home"){
        setfooter({f1 : true})
        setnavbar({n2 : true})
        setbrdcum({b1 : true})
      }
      else if (localStorage.getItem("navbar") === "homewave"){
        setfooter({f5 : true})
        setbrdcum({b1 : true})
        setnavbar({n4 : true})
      }
      else if (localStorage.getItem("navbar") === "homevideo"){
        setfooter({f1 : true})
        setnavbar({n6 : true})
        setbrdcum({b3 : true})
      }
      else if (localStorage.getItem("navbar") === "homevideotwo"){
        setfooter({f4 : true})
        setnavbar({n4 : true})
        setbrdcum({b4 : true})
      } else {
        setfooter({f1 : true})
        setnavbar({n2 : true})
      }
    }, [])
    

    const [footer , setfooter] = useState({f1 : true})
    const [navbar , setnavbar] = useState({n1 : true})
    const [brdcum , setbrdcum] = useState({n1 : true})
    
    return (
        <>
            {homepage && <Navbar navbar = {navbar}/>}
            <Routes>
                <Route path="/" element={<Home setfooter = {setfooter} setnavbar = {setnavbar} setbrdcum = {setbrdcum}/>} />
                <Route path="/contact" element={< Contact brdcum = {brdcum}/>} />
            </Routes>
            {footerpage && <Footer footer = {footer}/>}
        </>
    )
}

export default Routing