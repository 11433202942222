import { Link } from 'react-router-dom'
import React  from 'react'

import brasil from '../../assets/images/brasilMap.png'
import logo1 from "../../assets/images/logo-branca.png";

const Main = () => {
    
  return (
    <>
        <footer>
            <div className="top_footer" id="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="abt_side">
                                <div className="logo"> <img src={logo1} alt="logo logma" /></div>
                                <ul>
                                    <li className="footer-info">
                                        <p className="foooter-name"> Logma Engenharia</p> 
                                        <p className="footer-contact">(35) 9 9727 - 2226 </p> 
                                        <p className="footer-contact"> diretoria@logmaengenharia.com.br </p> 
                                        <p className="footer-contact"> engenharia@logmaengenharia.com.br </p> 
                                    </li>
                                </ul>
                                <ul className="social_media">
                                    <li><Link to="#"><i className="icofont-facebook"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-twitter"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-instagram"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                        
                        <div className="col-lg-2 col-md-6 col-12">
                            <div className="try_out">
                                <ul>
                                <li>
                                    <Link to="#">
                                       <img className="image-footer" src={brasil} alt="brasil" />
                                    </Link>
                                </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom_footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <p>© Copyrights 2022. All rights reserved.</p>
                        </div>
                        <div className="col-md-6">
                            <p className="developer_text">Developed by <Link to="https://www.linkedin.com/in/mateus-henrique-silva-483071185/" target="blank">Mateus Silva</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </footer> 
    </>
  )
}

export default Main
