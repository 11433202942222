import React from 'react'

import cid1 from '../../../assets/images/cid-1.1.png'

const Main = ({gredient}) => {
  return (
    <>
        <section className={`row_am modern_ui_section ${gredient && "gredient-bg"}`} >
            {gredient &&
            <div className="modernui_section_bg modernui-gredient"> <img src="assets/images/section-bg.png" alt="section"/> </div>}
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="ui_text">
                        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                            <br />
                            <br />
                            <h2><span>Cidadania</span></h2>
                            <h6>
                                “Colaborar com a na qual estamos inseridos e contribuir para a preservação do meio ambiente
                                são nossos deveres”
                            </h6>
                            <br />
                            <p>
                                A LOGMA possui obras em todo o
                                País, sendo assim, temos a
                                oportunidade de conviver com uma
                                imensa diversidade cultural e social
                                de comunidades que nos
                                recepcionam com todo carinho nas
                                localidades em que atuamos.
                            </p>
                            <p>
                                Em agradecimento a esse apoio, buscamos constantemente colaborar
                                de forma efetiva com o local em que estamos inseridos, auxiliando
                                instituições carentes e realizando doações para a população
                                necessitada. Outra preocupação é a preservação do meio ambiente,
                                portanto, nossas obras são realizadas obedecendo à separação do
                                material reciclável, além de contar com a constante orientação de
                                nossos colaboradores no que se refere aos cuidados com o
                                ambiente no qual trabalhamos.
                            </p>
                        </div>
                        <ul className="design_block">
                            <li data-aos="fade-up" data-aos-duration="1500">
                                <h4>APAE</h4>
                                <p>
                                    Juntamente com a Associação de Pais e Amigos dos Excepcionais
                                    (APAE), a Logma promoveu melhorias na estrutura da unidade e
                                    instalou um eficiente sistema de climatização.
                                </p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1500">
                                <h4>Projeto Social Sala do Saber</h4>
                                <p>
                                    Apoio educacional complementar oferecido ao ensino
                                    regular, proporcionando o acompanhamento das
                                    atividades escolares, juntamente com orientação sobre
                                    higiene, limpeza, educação ambiental, dentre outros.
                                </p>
                            </li>
                        </ul>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="ui_images" data-aos="fade-in" data-aos-duration="1500">
                            <img className="moving_position_animatin" src={cid1} alt="animatin" />
                        <div className="right_img">
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <br />
        <br />
    </>
  )
}

export default Main