import React from 'react'
import frame from '../../../assets/images/capa-seguranca-new.png'

const Main = () => {
  return (
    <>  
        <br />
        <section className="row_am about_app_section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="about_img" data-aos="fade-in" data-aos-duration="1500">
                        <div className="frame_img">
                            <img className="moving_position_animatin" src={frame} alt="frame"/>
                        </div>
                        <div className="screen_img">
                           
                        </div>
                        </div>
                    </div>                   
                    <div className="col-lg-6">
                        <div className="about_text">
                        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                            <h2>Segurança no <span>Trabalho</span></h2>
                            <h6>"Segurança é uma questão de honra"</h6>
                            <br />
                            <h5>
                                Garantir a segurança dos nossos
                                colaboradores é fator que permeia todo
                                o trabalho exercido pela Logma.
                            </h5>
                            <br />
                            <p>
                                Assegurar um ambiente organizacional agradável, seguro e que preze
                                pela integridade física de nossos profissionais é questão prioritária em
                                nossa empresa. Para tanto, nossas integrações são realizadas com o
                                acompanhamento de engenheiros de segurança, todos os colaboradores recebem os EPIs de qualidade 
                                necessários para a melhor execução
                                do trabalho e realizamos treinamentos frequentes em nossos canteiros
                                para o aprimoramento das atividades exercidas, garantindo a segurança
                                dos envolvidos. A soma dessas ações garante a conclusão de nossas
                                obras, atingindo a META ZERO ACIDENTES COM AFASTAMENTO.
                            </p>
                            
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <br />
        <br /> 
    </>
  )
}

export default Main