import OwlCarousel from 'react-owl-carousel'
import React from 'react'
import img1 from '../../../assets/images/itafos.png'
import img2 from '../../../assets/images/intercement.png'
import img3 from '../../../assets/images/jbs.png'
import img4 from '../../../assets/images/Galvani.png'
import img5 from '../../../assets/images/GrupoPetrolis.png'
import img6 from '../../../assets/images/CafeBrasil.png'
import img7 from '../../../assets/images/cimentotupi.png'
import img8 from '../../../assets/images/cimpor.png'
import img9 from '../../../assets/images/Morroverde.png'
import img10 from '../../../assets/images/pagold.png'
import img11 from '../../../assets/images/Ubyfol.png'
import img12 from '../../../assets/images/Votorantim.png'
import img13 from '../../../assets/images/yara-logo-min.png'

const Main = () => {
    const company_slider = {
        loop:true,
        margin:10,
        nav:false,
        autoplay: true,
        smartSpeed: 1500,
        dots: true, 
        responsive:{
            0:{
                items:2
            },
            600:{
                items:3
            },
            1000:{
                items:5
            }
        }
    }

  return (
    <>
        <br />
        <section className="row_am trusted_section">
            <div className="container">
                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                <br />
                <h2>Atendemos a diversas <span>companhias</span></h2>
                <br />
                <p>
                    A LOGMA é uma empresa de engenharia industrial que atende a diversas empresas desde 2004. <br />
                    Possui vasta experiência executando obras de montagem, manutenção mecânica e 
                    instalação de equipamento, <br /> 
                    sempre prezando a segurança e satisfação dos clientes. 
                </p>
                <br />
                <br />
                </div>
                <div className="company_logos" >
                <OwlCarousel id="company_slider" {...company_slider} className="owl-carousel owl-theme owl-loaded owl-drag">
                    <div className="item">
                        <div className="logo">
                            <img src={img1} alt="logo" />
                        </div>
                    </div>
                    <div className="item">
                        <div className="logo">
                            <img src={img2} alt="logo" />
                        </div>
                    </div>
                    <div className="item">
                        <div className="logo">
                            <img src={img3} alt="logo" />
                        </div>
                    </div>
                    <div className="item">
                        <div className="logo">
                            <img src={img4} alt="logo" />
                        </div>
                    </div>
                    <div className="item">
                        <div className="logo">
                            <img src={img5} alt="logo" />
                        </div>
                    </div>
                    <div className="item">
                        <div className="logo">
                            <img src={img6} alt="logo" />
                        </div>
                    </div>
                    <div className="item">
                        <div className="logo">
                            <img src={img7} alt="logo" />
                        </div>
                    </div>
                    <div className="item">
                        <div className="logo">
                            <img src={img8} alt="logo" />
                        </div>
                    </div>
                    <div className="item">
                        <div className="logo">
                            <img src={img9} alt="logo" />
                        </div>
                    </div>
                    <div className="item">
                        <div className="logo">
                            <img src={img10} alt="logo" />
                        </div>
                    </div>
                    <div className="item">
                        <div className="logo">
                            <img src={img11} alt="logo" />
                        </div>
                    </div>
                    <div className="item">
                        <div className="logo">
                            <img src={img12} alt="logo" />
                        </div>
                    </div>
                    <div className="item">
                        <div className="logo">
                            <img src={img13} alt="logo" />
                        </div>
                    </div>
                </OwlCarousel>
                </div>
                <br />
            </div>
        </section>
    </>
  )
}

export default Main