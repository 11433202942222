import React from 'react'
import img1 from '../../../assets/images/expectativa.png'

const Main = ({gredient}) => {
  return (
    <>
        <section className={`row_am modern_ui_section ${gredient && "gredient-bg"}`} >
            {gredient &&
            <div className="modernui_section_bg modernui-gredient"> <img src="assets/images/section-bg.png" alt="section"/> </div>}
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="ui_text">
                        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                            <h2><span>Superação das expectativas</span></h2>
                            <br />
                            <h6>"Queremos fazer o olho do cliente brilhar"</h6>
                            <br />
                            <p>
                                A LOGMA é uma empresa de engenharia industrial com início de atividades em 2004 com larga experiência no ramo de prestação de
                                serviços na área industrial, executando montagem e manutenção mecânica incluindo processos de fabricação e instalação de
                                equipamentos, bem como start-up dos mesmos.
                            </p>
                            <p>
                                Com a experiência adquirida nos diversos setores da indústria nacional, usando tecnologia de ponta e sempre mantendo um elevado
                                nível de qualidade nos trabalhos executados, visando atender as metas contratuais objeto da presente proposta e garantindo os níveis
                                de qualidade e produtividade, a LOGMA assumirá sempre uma posição de antecipação às dificuldades a serem enfrentadas, visando o
                                conjunto como uma preocupação permanente no decorrer da obra e mantendo junto ao cliente a identificação precisa daquilo que é
                                valioso para ela.
                            </p>
                        </div>
                        <br></br>
                        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                            <br />
                            <h2><span>Áreas de atuação</span></h2>
                            <br />
                        </div>

                        <ul className="design_block">
                            <li data-aos="fade-up" data-aos-duration="1500">
                            <h4>Montagem e manutenção industrial eletromecânica</h4>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1500">
                            <h4>Instrumentação nas implatantações industriais</h4>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1500">
                            <h4>Manutenção, reforma e modernização de unidades fabris</h4>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1500">
                            <h4>Fabricação de caldeiraria</h4>
                            </li>
                        </ul>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="ui_images" data-aos="fade-in" data-aos-duration="1500">
                        <div className="left_img">
                            <img className="moving_position_animatin" src={img1} alt="animatin" />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <br />
        <br />
    </>
  )
}

export default Main